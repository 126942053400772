.el-col-sm-10[data-v-03e0cfc0] {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-sm-10 div[data-v-03e0cfc0] {
  padding-left: 2px;
  box-sizing: border-box;
}
.el-col-sm-2[data-v-03e0cfc0] {
  border-right: 1px solid #b9b9b9;
  text-align: right;
  padding-right: 2px;
  font-size: 14px;
  height: 34px;
  background-color: #ecf7f9;
  line-height: 34px;
}
.el-row[data-v-03e0cfc0] {
  display: flex;
  border-left: 1px solid #b9b9b9;
  border-bottom: 1px dashed #b9b9b9;
}
.el-row[data-v-03e0cfc0]:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row[data-v-03e0cfc0]:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.top_select[data-v-03e0cfc0] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.el-checkbox-group[data-v-03e0cfc0] {
  padding-top: 5px;
}
.flex_style[data-v-03e0cfc0] {
  display: flex;
  align-items: center;
}
.el-radio[data-v-03e0cfc0] {
  margin-right: 4px;
}
.span_style[data-v-03e0cfc0] {
  display: inline-block;
  cursor: pointer;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
[data-v-03e0cfc0] textarea {
  min-height: 145px !important;
}
.item_icon[data-v-03e0cfc0] {
  display: inline-block;
  padding-right: 5px;
  cursor: pointer;
}
.item_icon i[data-v-03e0cfc0] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
[data-v-03e0cfc0] .el-checkbox-button__inner {
  padding: 5px 5px;
}
[data-v-03e0cfc0] .el-radio__label {
  padding-left: 4px;
}
.div_p[data-v-03e0cfc0] {
  line-height: 28px;
  border-bottom: 1px solid #efefef;
  height: 28px;
}
.div_p p[data-v-03e0cfc0] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
}
.div_p[data-v-03e0cfc0]:last-child {
  border-bottom: none;
}